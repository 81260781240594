<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-table
        grid
        title=""
        :data="grid5.data"
        :columns="grid5.columns"
        hide-header
        hide-bottom
        :rows-per-page-options="[0]"
        virtual-scroll
      >
        <template v-slot:top-right>
          <q-btn-group outline >
            <c-btn label="안전작업허가서 발행" icon="add" color="purple"/>
            <c-btn v-if="editable&&!disabled" label="추가" icon="add"/>
            <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" />
          </q-btn-group>
        </template>
        <template v-slot:item="props">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <q-card class="mobileTableCardLayer">
              <q-card-section horizontal class="bg-grey-2">
                <q-card-section class="q-pt-xs">
                  <div class="text-grid-title q-mt-sm q-mb-xs">[{{ props.row.permitTypeName }}]{{ props.row.workSummary }}</div>
                </q-card-section>
              </q-card-section>
              <q-card-section class="grid-card-etc">
                <div class="text-grid-etc">
                  {{ props.row.permitDate }}
                </div>
                <div class="text-grid-etc">
                  {{ getSupWorkName(props.row.supplementWorkTypeNames) }}
                </div>
                <div :class="['text-grid-etc', getColorStatus(props.row.swpStepCd)]">
                  {{ props.row.swpStepName }}
                </div>
              </q-card-section>
            </q-card>
          </div>
        </template>
      </q-table>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid5.data.length === 0">
        <q-card class="mobileTableCardLayer">
          <q-card-section horizontal class="bg-grey-2 text-center">
            <q-card-section class="q-pt-xs">
              <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
            </q-card-section>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'outernal-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      comboItems1: [
        {
          code: '1',
          codeName: '고장분류 #1',
        },
        {
          code: '2',
          codeName: '고장분류 #2',
        },
      ],
      comboItems2: [
        {
          code: '1',
          codeName: '고장코드 #1',
        },
        {
          code: '2',
          codeName: '고장코드 #2',
        },
      ],
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'OUTER_AUDIT_ATTACH',
        taskKey: '',
      },
      editable: true,
      data: {
        col1: null,
        col2: null,
        col3: '2022-11-22 10:10:00',
        col4: '2022-11-22 13:40:00',
      },
      grid5: {
        columns: [
          {
            // 사업장
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            // 작업개요
            name: 'workSummary',
            field: 'workSummary',
            label: '작업개요',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:250px',
          },
          {
            // 진행단계
            name: 'swpStepName',
            field: 'swpStepName',
            label: '진행단계',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재진행단계',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 허가일
            name: 'permitDate',
            field: 'permitDate',
            label: '허가일',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 허가서구분
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: '허가서구분',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 보충작업
            name: 'supplementWorkTypeNames',
            field: 'supplementWorkTypeNames',
            label: '보충작업',
            align: 'left',
            sortable: true,
            style: 'width:200px',
          },
          {
            // 신청부서
            name: 'applicationDeptName',
            field: 'applicationDeptName',
            label: '신청부서',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 발급부서
            name: 'issuedDeptName',
            field: 'issuedDeptName',
            label: '발급부서',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.grid5.data = [
        {
          "editFlag": null,
          "regUserId": null,
          "regUserName": null,
          "chgUserId": null,
          "chgUserName": null,
          "userDeptCd": null,
          "approvalTypeCd": "APTC000001",
          "approvalStatusCd": "ASC9999999",
          "approvalStatusName": "결재완료",
          "sysApprovalRequestId": "A000000049",
          "plantCdList": null,
          "regDt": "2022-11-25 09:30:10",
          "regDtStr": null,
          "chgDt": "2022-11-25 09:30:10",
          "chgDtStr": null,
          "sopWorkPermitId": "WP00000039",
          "plantCd": "1000",
          "plantName": "사업장1",
          "mdmSopId": "",
          "sopName": "",
          "permitNo": "WPO-2022-00022",
          "swpStepCd": "SS00000005",
          "swpStepName": "승인완료",
          "applicationDeptCd": "D10005",
          "applicationDeptUserName": "생산팀 A / 관리자",
          "applicationDeptName": "생산팀 A",
          "applicationUserId": "U000000001",
          "applicationUserName": "관리자",
          "permitTypeCd": "SPT0000001",
          "permitTypeName": "일반",
          "permitDate": "2022-10-27",
          "workStartTime": "08:00",
          "workEndTime": "17:00",
          "processCd": "P00001-002",
          "processName": "건조-탈수",
          "workLocation": "장소",
          "workManagerId": "U000000001",
          "workManagerName": null,
          "equipmentCd": "EC00000023",
          "equipmentName": "설비 #00023",
          "workSummary": "A동 증설로 인한 고소작업",
          "specialRequirements": "",
          "cancelRemark": "",
          "actionRemark": "",
          "maintenanceDeptCheckUserId": "",
          "maintenanceDeptEntryUserId": "",
          "workCompleteTime": "",
          "workCompleteEntryUserId": "",
          "workCompleteWorkUserId": "",
          "issuedDeptCd": "D10005",
          "issuedDeptUserName": "생산팀 A / 관리자",
          "issuedDeptName": "생산팀 A",
          "issuedUserId": "U000000001",
          "issuedUserName": "관리자",
          "approvalDeptCd": "D10005",
          "approvalUserId": "U000000006",
          "relationCooperation1DeptCd": "",
          "relationCooperation1UserId": "",
          "relationCooperation2DeptCd": "",
          "relationCooperation2UserId": "",
          "vendorCd": "VEN0000000",
          "vendorName": "자체",
          "overWorkFlag": "",
          "psmFlag": "N",
          "sopMocId": null,
          "assessWriteUserId": "U000000001",
          "assessApprovalUserId": "U000000006",
          "swpWorkRequestCd": null,
          "swpWorkRequestName": null,
          "swpWorkRequestType": null,
          "returnRemark": "",
          "vendorFlag": null,
          "supplementWorkTypeNames": "고소",
          "supWorks": null,
          "deleteSupWorks": null,
          "checkResults": null,
          "deleteCheckResults": null,
          "gases": null,
          "gasChecks": null,
          "deleteGasChecks": null,
          "deleteGasCheckVals": null,
          "maps": null,
          "deleteMaps": null,
          "workers": null,
          "deleteWorkers": null,
          "assessments": null,
          "deleteAssessments": null,
          "overs": null,
          "deleteOvers": null,
          "protectiveGears": null,
          "deleteProtectiveGears": null
        },
        {
          "editFlag": null,
          "regUserId": null,
          "regUserName": null,
          "chgUserId": null,
          "chgUserName": null,
          "userDeptCd": null,
          "approvalTypeCd": "APTC000001",
          "approvalStatusCd": "ASC9999999",
          "approvalStatusName": "결재완료",
          "sysApprovalRequestId": "A000000041",
          "plantCdList": null,
          "regDt": "2022-11-25 09:30:10",
          "regDtStr": null,
          "chgDt": "2022-11-25 09:30:10",
          "chgDtStr": null,
          "sopWorkPermitId": "WP00000035",
          "plantCd": "1000",
          "plantName": "사업장1",
          "mdmSopId": "SOP0000001",
          "sopName": "베어링 하우징 교체2",
          "permitNo": "WPO-2022-00018",
          "swpStepCd": "SS00000005",
          "swpStepName": "승인완료",
          "applicationDeptCd": "D10005",
          "applicationDeptUserName": "생산팀 A / 관리자",
          "applicationDeptName": "생산팀 A",
          "applicationUserId": "U000000001",
          "applicationUserName": "관리자",
          "permitTypeCd": "SPT0000005",
          "permitTypeName": "화기",
          "permitDate": "2022-10-20",
          "workStartTime": "08:00",
          "workEndTime": "17:00",
          "processCd": "P00001-001",
          "processName": "RM-T3 공정",
          "workLocation": "2층",
          "workManagerId": null,
          "workManagerName": null,
          "equipmentCd": "EC00000074",
          "equipmentName": "설비 #00074",
          "workSummary": "A3구역 밀폐공간 가스제거 작업",
          "specialRequirements": "",
          "cancelRemark": "",
          "actionRemark": "",
          "maintenanceDeptCheckUserId": "",
          "maintenanceDeptEntryUserId": "",
          "workCompleteTime": "",
          "workCompleteEntryUserId": "",
          "workCompleteWorkUserId": "",
          "issuedDeptCd": "D10005",
          "issuedDeptUserName": "생산팀 A / 관리자",
          "issuedDeptName": "생산팀 A",
          "issuedUserId": "U000000001",
          "issuedUserName": "관리자",
          "approvalDeptCd": "D10005",
          "approvalUserId": "U000000026",
          "relationCooperation1DeptCd": "",
          "relationCooperation1UserId": "",
          "relationCooperation2DeptCd": "",
          "relationCooperation2UserId": "",
          "vendorCd": "VEN0000000",
          "vendorName": "자체",
          "overWorkFlag": "",
          "psmFlag": "N",
          "sopMocId": null,
          "assessWriteUserId": "",
          "assessApprovalUserId": "",
          "swpWorkRequestCd": null,
          "swpWorkRequestName": null,
          "swpWorkRequestType": null,
          "returnRemark": null,
          "vendorFlag": null,
          "supplementWorkTypeNames": "밀폐공간",
          "supWorks": null,
          "deleteSupWorks": null,
          "checkResults": null,
          "deleteCheckResults": null,
          "gases": null,
          "gasChecks": null,
          "deleteGasChecks": null,
          "deleteGasCheckVals": null,
          "maps": null,
          "deleteMaps": null,
          "workers": null,
          "deleteWorkers": null,
          "assessments": null,
          "deleteAssessments": null,
          "overs": null,
          "deleteOvers": null,
          "protectiveGears": null,
          "deleteProtectiveGears": null
        },
      ]
    },
    getSupWorkName(_val) {
      if (_val === null) {
        return '';
      } else {
        return '보충작업('+_val+')';
      }
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'SS00000001': // 작성중
          cls = 'text-primary';
          break;
        case 'SS00000005': // 승인완료
          cls = 'text-positive';
          break;
        case 'SS00000010': // 연장
          cls = 'text-accent';
          break;
        case 'SS00000015': // 취소
          cls = 'text-red';
          break;
        case 'SS00000020': // 작업완료
          cls = 'text-orange-10';
          break;
      }
      return cls;
    },
  }
};
</script>